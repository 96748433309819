import React from 'react';

import { useAvalara } from './hooks';
import AvalaraConfig from './AvalaraConfig';
import AvalaraConnector from './AvalaraConnector';

const AdminAvalara = () => {
  const avalara  = useAvalara();

  return <div style={{ maxWidth: 990 }}>
    {avalara.isConnected() ? <AvalaraConfig/> : <AvalaraConnector/>}
  </div>;
};

export default AdminAvalara;