import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from '@commonsku/styles';

import { find, map, orderBy } from 'lodash';
import { useAvalara } from './hooks';

const AvalaraTaxCodeSelect = ({ value, onChange, ...props }) => {
  const avalara = useAvalara();
  const [taxCodes, setTaxCodes] = useState([]);
  useEffect(() => {
    avalara.getAvalaraTaxCodes().then(({ json }) => {
      setTaxCodes(json.tax_codes);
    });
  });

  const options = map(orderBy(taxCodes, 'avalara_tax_code'), ({
    avalara_tax_code, avalara_tax_code_description,
  }) => {
    return {
      value: `${avalara_tax_code} ${avalara_tax_code_description}`,
      avalara_tax_code,
      label: <div>
        <div style={{ fontWeight: 'bold ' }}>{avalara_tax_code}</div>
        <div style={{ fontSize: '0.8rem' }} className="avalara-tax-code-description">
          {avalara_tax_code_description}
        </div>
      </div>
    };
  });

  return <Select
    value={find(options, { avalara_tax_code: value })}
    options={options}
    onChange={(option) => {
      return onChange(option.avalara_tax_code);
    }}
    {...props}
  />;
};

export default styled(AvalaraTaxCodeSelect)`
  .commonsku-styles-select__value-container .avalara-tax-code-description {
    display: none;
  }
`;