import { find, get, map, upperFirst } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Button, Select, Col, Row, Label, LabeledCheckbox, } from '@commonsku/styles';
import { useAvalara } from './hooks';
import { oauth } from '../../utils';
import EditAvalaraCategoriesTable from './EditAvalaraCategoriesTable';
import AvalaraCategorySelect from './AvalaraCategorySelect';
import Loading from '../Loading';

const AvalaraCompanyCodeSelect = () => {
  const avalara = useAvalara();
  const [loading, setLoading] = useState(false);
  const [avalaraCompanies, setAvalaraCompanies] = useState([]);
  useEffect(() => {
    setLoading(true);
    oauth('GET', 'avalara/companies', {})
      .then(({ json }) => {
        setAvalaraCompanies(get(json, 'companies'));
      })
      .finally(() => {
        setLoading(false);
      })
    ;
  }, []);

  const options = map(avalaraCompanies, (company) => {
    return {value: company.companyCode, label: company.name};
  });

  return loading ? <Loading/> : <Select
    placeholder="Select from API"
    value={find(options, { value: avalara.avalara_company_code })}
    options={options}
    onChange={(option) => {
      avalara.updateDefaultCompanyCode(option.value);
    }}
  />;
};

const AvalaraDefaultCategorySelect = () => {
  const avalara = useAvalara();

  return <AvalaraCategorySelect
    placeholder="Select Default Category"
    value={avalara.default_avalara_category_id}
    onChange={(value) => {
      avalara.updateDefaultCategory(value);
    }}
  />;
};

const AvalaraShippingCategorySelect = () => {
  const avalara = useAvalara();

  return <AvalaraCategorySelect
    placeholder="Select Default Shipping Category"
    value={avalara.default_avalara_shipping_category_id}
    onChange={(value) => {
      avalara.updateShippingCategory(value);
    }}
  />;
};

const AvalaraConfig = () => {
  const avalara = useAvalara();
  const avalara_environment = avalara.getEnvironment();

  return <div>
    <Row style={{ padding: '0 7px 20px' }}>
      <Button onClick={() => {avalara.disconnect();}}>Disconnect from Avalara {upperFirst(avalara_environment)}</Button>
    </Row>
    <Row>
      <Col xs md={3} px={7}>
        <Label style={{ fontWeight: 'bold', lineHeight: '42px' }}>Default Company:</Label>
      </Col>
      <Col xs md={4}>
        <AvalaraCompanyCodeSelect/>
      </Col>
    </Row>
    <Row>
      <Col xs md={3} px={7}>
        <Label style={{ fontWeight: 'bold', lineHeight: '42px' }}>Default Category:</Label>
      </Col>
      <Col xs md={4}>
        <AvalaraDefaultCategorySelect/>
      </Col>
    </Row>
    <Row>
      <Col xs md={3} px={7}>
        <Label style={{ fontWeight: 'bold', lineHeight: '42px' }}>Default Shipping Category:</Label>
      </Col>
      <Col xs md={4}>
        <AvalaraShippingCategorySelect/>
      </Col>
    </Row>
    <Row style={{ padding: '15px 7px' }}>
      <Col xs md={4} px={7}>
        <LabeledCheckbox
          label="Disable document recording"
          checked={!avalara.avalara_recording}
          onChange={(e) => {
            return avalara.updateRecording(!e.target.checked);
          }}
        />
      </Col>
      <Col xs md={4} px={7}>
        <LabeledCheckbox
          label="Test mode (mode)" checked={avalara.avalara_testing}
          onChange={(e) => {
            return avalara.updateTesting(e.target.checked);
          }}
        />
      </Col>
    </Row>
    <EditAvalaraCategoriesTable/>
  </div>;
};

export default AvalaraConfig;
