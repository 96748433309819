import { find, map, get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Popup, Button, H4 } from '@commonsku/styles';
import AvalaraCategoriesTable from './AvalaraCategoriesTable';
import { useAvalara } from './hooks';

const AddPresetAvalaraCategoryPopup = ({ onChange, onClose, ...props }) => {
  const avalara = useAvalara();
  const [selection, setSelection] = useState([]);
  const avalara_categories = useSelector((state) => {
    return get(state, 'entities.avalara_categories');
  });

  return <Popup
    {...props}
    style={{ padding: '15px 25px' }}
    header={
      <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
        <H4>Add Preset Categories</H4>
        <Row>
          <Button style={{ margin: 5 }} primary size="small" onClick={() => {
            Promise.all(map(selection, (row) => {
              if (!find(avalara_categories, { category: row.category })) {
                return avalara.createCategory(row);
              }
            })).then(() => {
              onClose();
            });
          }}>Add Categories</Button>
          <Button style={{ margin: 5 }} secondary size="small" onClick={onClose}>Cancel</Button>
        </Row>
      </Row>
    }
  >
    <AvalaraCategoriesTable
      checkbox={true}
      avalara_categories={[
        {
          category: 'SERVICE', avalara_tax_code: 'S0000001',
          avalara_tax_code_description: 'Services (required to complete a sale other than delivery & installation)',
        },
        {
          category: 'SCREENPRINT', avalara_tax_code: 'SP102800',
          avalara_tax_code_description: 'Screen Printing',
        },
        {
          category: 'PRINT', avalara_tax_code: 'SP106394',
          avalara_tax_code_description: 'Publishing/Printing - Copying Services',
        },
        {
          category: 'WAREHOUSING', avalara_tax_code: 'SW110000',
          avalara_tax_code_description: 'Warehousing',
        },
        {
          category: 'DELIVERY_S&H', avalara_tax_code: 'FR020100',
          avalara_tax_code_description: 'Charges for delivery, shipping, S/H. Transportation of product + charges for handling or preparing the product. Paid to the seller not to Shipper.',
        },
        {
          category: 'SHIPPING', avalara_tax_code: 'FR020200',
          avalara_tax_code_description: 'Shipping Only - common carrier - FOB origin',
        },
        {
          category: 'NON_TAXABLE', avalara_tax_code: 'NT',
          avalara_tax_code_description: 'Non-Taxable Product',
        },
        {
          category: 'MERCHANDISE', avalara_tax_code: 'P0000000',
          avalara_tax_code_description: 'Tangible Personal Property (TPP)',
        },
        {
          category: 'APPAREL', avalara_tax_code: 'PC030100',
          avalara_tax_code_description: 'Clothing & related products (B2B) - General',
        },
        {
          category: 'HEADWEAR', avalara_tax_code: 'PC030129',
          avalara_tax_code_description: 'Clothing & related products (B2B) - Hats and caps',
        },
        {
          category: 'APPAREL_CHILDREN_<11', avalara_tax_code: 'PC040501',
          avalara_tax_code_description: 'Clothing And Related Products - Children\'s Clothing (below age 11)',
        },
        {
          category: 'APPAREL_CHILDREN_11-14', avalara_tax_code: 'PC040502',
          avalara_tax_code_description: 'Clothing And Related Products - Children\'s Clothing (ages 11 - 14)',
        },
        {
          category: 'FITNESS', avalara_tax_code: 'PE200706',
          avalara_tax_code_description: 'Exercise Supplies',
        },
        {
          category: 'FOOD_GENERAL_SSUTA', avalara_tax_code: 'PF050001',
          avalara_tax_code_description: 'Food & Food Ingredients - General (per SSUTA)',
        },
        {
          category: 'FOOD_CANDY', avalara_tax_code: 'PF050300',
          avalara_tax_code_description: 'Food & Food Ingredients - Candy',
        },
        {
          category: 'FOOD_NUTS_POPCORN', avalara_tax_code: 'PF051427',
          avalara_tax_code_description: 'Food / Nuts & Seeds / Nuts, Seeds and Popcorn That Contain Sugar That Are Not Commonly Thought Of As Candy / No Flour / Individual Sized Portion / Equal To 2.499 Ounces Or Less / Or Is Labeled As A Single Serving / Or Size Is Unknown',
        },
        {
          category: 'FOOD_SWEETS', avalara_tax_code: 'PF050075',
          avalara_tax_code_description: 'Food & food ingredients -bakery products - sweets, chocolates, confectionery',
        },
        {
          category: 'FOOD_BAKED_GOODS', avalara_tax_code: 'PF160042',
          avalara_tax_code_description: 'Prepared food / bakery / seller prepared / single serving / packaged',
        },
        {
          category: 'GLASSWARE', avalara_tax_code: 'PG068810',
          avalara_tax_code_description: 'Glassware',
        },
        {
          category: 'OFFICE_SUPPLIES', avalara_tax_code: 'PO100000',
          avalara_tax_code_description: 'Office Supplies',
        },
      ]}
      onSelect={(selection) => {
        setSelection(selection);
      }}
    />
  </Popup>;
};

export default AddPresetAvalaraCategoryPopup;
