import { map, filter, isEmpty, uniqBy, get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col, Popup, Dropzoned, Button, H4, colors } from '@commonsku/styles';
import { csvFilesToJson } from '../../helpers/file';
import AvalaraCategoriesTable from './AvalaraCategoriesTable';
import { useAvalara } from './hooks';

const ImportAvalaraCategoryPopup = ({ onChange, onClose, ...props }) => {
  const avalara = useAvalara();
  const avalara_categories = useSelector((state) => {
    return get(state, 'entities.avalara_categories');
  });
  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState([]);
  return <Popup
    {...props}
    style={{ padding: '15px 25px' }}
    header={
      <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
        <H4>Import Categories</H4>
        <Row>
          {rows.length > 0 && <Button primary size="small" style={{ margin: 5 }} onClick={() => {
            Promise.all(map(rows, (row) => {
              return avalara.createCategory(row);
            })).then(() => {
              onClose();
            });
          }}>Import</Button>}
          <Button style={{ margin: 5 }} secondary size="small" onClick={onClose}>Cancel</Button>
        </Row>
      </Row>
    }
  >
    <Row py={10}>
      <Col xs={12}>Only *.csv files will be accepted.</Col>
      <Col xs={12}>CSV file must include headers: category, avalara_tax_code</Col>
    </Row>
    <Row>
      <Dropzoned
        accept=".csv"
        showDroppedFiles={true}
        onDrop={async (acceptedFiles) => {
          const { data, errors } = await csvFilesToJson(acceptedFiles);
          const categorySet = new Set(map(avalara_categories, 'category'));

          setRows(uniqBy(
            filter(
              data,
              (row, i) => {
                if (categorySet.has(row.category)) {
                  errors.push({
                    row: i + 1,
                    code: 'DuplicateCategory',
                    message: `category "${row.category}" already exists`,
                  });
                  return false;
                } else if (!(row.category && row.avalara_tax_code)) {
                  errors.push({
                    row: i + 1,
                    code: 'MissingRequiredField',
                    message: `category and avalara_tax_code are required`,
                  });
                  return false;
                }
                return true;
              }
            ),
            'category'
          ));
          setErrors(errors);
        }}
      />
    </Row>
    {!isEmpty(errors) && <Row>
      {map(errors, (error, i) => {
        return <Col key={i} xs={12} style={{color: colors.special3}}>
          Row {error.row}: {error.code} - {error.message}
        </Col>;
      })}
    </Row>}
    {rows.length > 0 && <AvalaraCategoriesTable avalara_categories={rows}/>}
  </Popup>;
};

export default ImportAvalaraCategoryPopup;
