import { find, concat, identity, isEmpty, map, filter, get, keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Input, Table, THead, TR, TH, TBody, TD, LabeledCheckbox } from '@commonsku/styles';
import { useAvalara } from './hooks';
import AvalaraTaxCodeSelect from './AvalaraTaxCodeSelect';

const BorderTR = styled(TR)`
  padding-left: 7px;
  border-bottom: 1px solid #F1F1F1
`;

const CheckboxTD = styled(TD)`
  &&& {
    text-align: center;
    
    label {
      padding-left: 0;
      
      input {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
      } 
    }
  }
`;

const CheckboxTH = styled(TH)`
  &&& {
    text-align: center;
  
    label {
      padding-left: 0;
      
      input {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
      } 
    }
  }
`;

const CategoryTR = ({ checkbox, checked, category, allowEditing, onCheck, onDelete, onEdit }) => {
  const avalara = useAvalara();
  const [hover, setHover] = useState(false);
  const [taxCodes, setTaxCodes] = useState({});
  useEffect(() => {
    avalara.getAvalaraTaxCodes().then(({ json }) => {
      setTaxCodes(keyBy(json.tax_codes, 'avalara_tax_code'));
    });
  }, []);

  return <BorderTR
    style={{ paddingLeft: 7 }}
    onMouseOver={() => {
      setHover(true);
    }}
    onMouseLeave={() => {
      setHover(false);
    }}
  >
    {checkbox && <CheckboxTD><LabeledCheckbox
      checked={checked}
      onChange={onCheck}
    /></CheckboxTD>}
    <TD>{category.category}</TD>
    <TD width={200}>{category.avalara_tax_code}</TD>
    <TD>{get(taxCodes, [category.avalara_tax_code, 'avalara_tax_code_description'])}</TD>
    {allowEditing && (
      hover
        ? <TD width={150} height={46}>
          <Button size="tiny" style={{ marginRight: 5 }} onClick={() => {
            onEdit(category);
          }}>Edit</Button>
          <Button size="tiny" cta onClick={() => {
            onDelete(category);
          }}>Delete</Button>
        </TD>
        : <TD height={46}></TD>
    )}
  </BorderTR>;
};

const EditCategoryTR = ({ category, onEdit, onSave }) => {
  const [state, setState] = useState(category);

  return <BorderTR style={{ paddingLeft: 7 }}>
    <TD>
      <Input style={{marginBottom: '0'}} name="category" value={get(state, 'category') || ''} onChange={(e) => {
        setState({ ...state, category: e.target.value });
      }}/>
    </TD>
    <TD>
      <AvalaraTaxCodeSelect name="avalara_tax_code" value={get(state, "avalara_tax_code") || ''} onChange={(value) => {
        setState({ ...state, avalara_tax_code: value });
      }}/>
    </TD>
    <TD width={200}>&nbsp;</TD>
    <TD width={150}>
      <Button size="tiny" style={{ marginRight: 5 }} onClick={() => {
        onSave(state).finally(() => {
          onEdit(null);
        });
      }}>Save</Button>
      <Button size="tiny" cta onClick={() => {
        onEdit(null);
      }}>Cancel</Button>
    </TD>
  </BorderTR>;
};

const AvalaraCategoriesTable = ({
  avalara_categories, extraRows = [], checkbox = false, allowEditing=false,
  onSave = identity, onDelete = identity, onSelect = identity,
}) => {
  const [selection, setSelection] = useState([]);
  const [edit, setEdit] = useState(null);

  return <Table style={{ marginTop: 10 }}>
    <THead style={{ border: 'none' }}>
      <TR style={{ paddingLeft: 7 }}>
        {checkbox && <CheckboxTH><LabeledCheckbox
          checked={selection.length === avalara_categories.length}
          onChange={(e) => {
            if (e.target.checked) {
              setSelection(avalara_categories);
              onSelect(avalara_categories);
            } else {
              setSelection([]);
              onSelect([]);
            }
          }}
        /></CheckboxTH>}
        <TH>Category</TH>
        <TH>Avalara tax code</TH>
        <TH>Avalara tax code description</TH>
        <TH></TH>
      </TR>
    </THead>
    <TBody style={{ border: 'none' }}>
      {map(avalara_categories, (category, i) => {
        const Component = edit === category ? EditCategoryTR : CategoryTR;
        return <Component
          key={i}
          checked={!!find(selection, category)}
          checkbox={checkbox}
          allowEditing={allowEditing}
          category={category}
          onCheck={(e) => {
            const newSelection = e.target.checked ? concat(selection, [category]) : filter(selection, (selected) => {
              return selected.category !== category.category;
            });
            setSelection(newSelection);
            onSelect(newSelection);
          }}
          onEdit={(editingCategory) => {
            setEdit(editingCategory);
          }}
          onSave={onSave}
          onDelete={onDelete}
        />;
      })}
      {!isEmpty(extraRows) && map(extraRows, (cells, i) => {
        return <BorderTR key={`extra-${i}`}>{cells}</BorderTR>;
      })}
    </TBody>
  </Table>;
};

export default AvalaraCategoriesTable;