import Papa from 'papaparse';
import { concat, map, mapValues, trim } from 'lodash';

export const csvToJson = (file, options = {}) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (options.trim) {
          result = {
            ...result,
            data: map(result.data, (row) => {
              return mapValues(row, (v) => {
                return trim(v);
              });
            }),
          };
        }
        resolve(result);
      },
      error: (error) => {
        resolve({ data: [], errors: [error] });
      },
    });
  });
};

export const csvFilesToJson = async (files, options = {}) => {
  const results = await Promise.all(map(files, async (file) => {
    return await csvToJson(file, options);
  }));
  return results.reduce(({ data, errors }, current) => {
    console.log({ data, errors }, current);
    return {
      data: concat(data, current.data),
      errors: concat(errors, current.errors),
    };
  }, { data: [], errors: [] });
};

