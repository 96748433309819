import { get } from 'lodash';
import React, { useState } from 'react';

import { Button, Input, Col, Row, Label } from '@commonsku/styles';
import { useAvalara } from './hooks';

const AvalaraConnector = () => {
  const avalara = useAvalara();
  const [state, setState] = useState({
    avalara_account_id: '',
    avalara_license_key: '',
  });
  const [error, setError] = useState('');
  const connect = (avalara_environment) => {
    setError('');
    avalara.connect({
      avalara_account_id: state.avalara_account_id,
      avalara_license_key: state.avalara_license_key,
      avalara_environment,
    }).then((response) => {
      if (!get(response, 'payload.company_data.avalara_enable')) {
        setError('Invalid Avalara Credential');
      }
    });
  };

  return <div>
    {error && <Row>
      <Col xs md={8} mdOffset={4} style={{ color: 'red' }}>{error}</Col>
    </Row>}
    <Row>
      <Col xs md={4} px={7}>
        <Label style={{ fontWeight: 'bold' }}>Avalara Account ID:</Label>
      </Col>
      <Col xs md={8}>
        <Input value={state.avalara_account_id || ''} onChange={(e) => {
          setState({
            ...state,
            avalara_account_id: e.target.value
          });
        }}/>
      </Col>
    </Row>
    <Row>
      <Col xs md={4} px={7}>
        <Label style={{ fontWeight: 'bold' }}>Avalara License Key:</Label>
      </Col>
      <Col xs md={8}>
        <Input type="password" autoComplete="new-password" value={state.avalara_license_key || ''} onChange={(e) => {
          setState({
            ...state,
            avalara_license_key: e.target.value
          });
        }}/>
      </Col>
    </Row>
    <Row>
      <Col xs md={4} py={12} mdOffset={4} mdStyle="padding-right: 8px;">
        <Button onClick={() => {
          connect('production');
        }}>Connect to Avalara Production</Button>
      </Col>
      <Col xs md={4} py={12} mdStyle="padding-left: 8px;">
        <Button onClick={() => {
          connect('sandbox');
        }}>Connect to Avalara Sandbox</Button>
      </Col>
    </Row>
  </div>;
};

export default AvalaraConnector;
