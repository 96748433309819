import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button, Row, TD, Link, Input, ShowPopup
} from '@commonsku/styles';
import { filter, get, isEmpty, map, orderBy } from 'lodash';
import { useAvalara } from './hooks';
import AvalaraCategoriesTable from './AvalaraCategoriesTable';
import ImportAvalaraCategoryPopup from './ImportAvalaraCategoryPopup';
import AddPresetAvalaraCategoryPopup from './AddPresetAvalaraCategoryPopup';
import AvalaraTaxCodeSelect from './AvalaraTaxCodeSelect';

const EditAvalaraCategoriesTable = () => {
  const avalara = useAvalara();
  const avalara_categories = useSelector((state) => {
    return get(state, 'entities.avalara_categories');
  });
  const [newRow, setNewRow] = useState(null);
  const [errors, setErrors] = useState({});
  const fields = ['category', 'avalara_tax_code'];

  return <div>
    <AvalaraCategoriesTable
      allowEditing={true}
      avalara_categories={orderBy(avalara_categories, 'category')}
      onSave={(category) => {
        return avalara.updateCategory(category);
      }}
      onDelete={(category) => {
        return avalara.deleteCategory(get(category, 'avalara_category_id'));
      }}
      extraRows={isEmpty(newRow) ? [] : [[
        <TD key="category">
          <Input
            style={{marginBottom: 0}}
            error={!isEmpty(errors.category)}
            name="category" value={get(newRow, "category") || ''}
            onChange={(e) => {
              setNewRow({
                ...newRow,
                category: e.target.value
              });
            }}
          />
        </TD>,
        <TD key="avalara_tax_code">
          <AvalaraTaxCodeSelect
            error={!isEmpty(errors.avalara_tax_code)}
            name="avalara_tax_code" value={get(newRow, "avalara_tax_code") || ''}
            onChange={(value) => {
              setNewRow({
                ...newRow,
                avalara_tax_code: value
              });
            }}
          />
        </TD>,
        <TD key="empty" width={200}>&nbsp;</TD>,
        <TD width={150} key="actions">
          <Button size="tiny" style={{ marginRight: 5 }} onClick={() => {
            const invalidFields = filter(['category', 'avalara_tax_code'], (requiredField) => {
              if (isEmpty(get(newRow, requiredField))) {
                setErrors({
                  ...errors,
                  [requiredField]: 'Required',
                });
                return true;
              }
            });
            if (isEmpty(invalidFields)) {
              avalara.createCategory(newRow)
              .then((a) => {
                setNewRow(null);
              })
              .catch((e) => {
                alert('Failed to save due to: Invalid/Duplicated category name');
                setErrors({
                  ...errors,
                  'category': 'Invalid/Duplicated category name'
                });
              })
              ;
            }
          }}>Save</Button>
          <Button cta size="tiny" onClick={() => {
            setNewRow(null);
          }}>Cancel</Button>
        </TD>,
      ]]}
    />
    <Row style={{ paddingLeft: 7 }}>
      <Link style={{ padding: '5px 7px' }} onClick={() => {
        if (isEmpty(newRow)) {
          setNewRow(fields.reduce((o, field) => {
            o[field] = '';
            return o;
          }, {}));
        }
      }}>+ Add New Category</Link>
      <ShowPopup
        popup={ImportAvalaraCategoryPopup}
        render={({ onClick }) => {
          return <Link style={{ padding: '5px 7px' }} onClick={onClick}>+ Import Categories</Link>;
        }}
      />
      <ShowPopup
        popup={AddPresetAvalaraCategoryPopup}
        render={({ onClick }) => {
          return <Link style={{ padding: '5px 7px' }} onClick={onClick}>+ Add Preset Categories</Link>;
        }}
      />
    </Row>
  </div>;
};

export default EditAvalaraCategoriesTable;